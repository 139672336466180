import React from "react";

import main from '../../img/SupportFaq/main.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="flex flex-col xl:flex-row justify-between pb-[40px] xl:pb-[80px]">
                <div className="mt-[100px] xl:mt-[140px] mx-[20px] xl:mx-0">
                    <h1 className="max-w-[331px] md:max-w-[608px] xl:max-w-[800px] manrope text-[#001F61] text-[28px] xl:text-[40px] font-medium relative z-10">{t('supportfaq.1')}</h1>
                    <p className="mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[600px] z-50 relative">{t('supportfaq.2')}</p>
                    <p className="mt-[40px] xl:mt-[60px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[600px] z-50 relative">{t('supportfaq.3')}</p>
                </div>
                <div className="overflow-hidden flex items-end justify-center xl:block mx-[20px] xl:mx-0">
                    <Fade right><img src={main} alt="" className="mt-[60px] xl:mt-[170px]"/></Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;