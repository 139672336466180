import React from "react";

import support from "../../img/Home/support.png"

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function SupportCenter () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[40px] xl:pt-[80px] pb-[80px] xl:pb-[160px] mx-[20px]">
                <p className="xl:max-w-[600px] textcolor roboto font-light text-[14px] xl:text-[16px]">{t('main.41')}</p>
                <p className="xl:max-w-[600px] textcolor roboto font-light text-[14px] xl:text-[16px] mt-[20px]">{t('main.42')}</p>
                <Link to='https://user.cgl-limited.net'><div className="button border border-[#0349DA] mt-[40px] z-50 relative">
                    <p className="text-[#0349DA] underline">{t('main.47')}</p>
                </div></Link>
                <div className="flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px]">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[517px]">{t('main.43')}</h1>
                        <p className="xl:max-w-[600px] textcolor mt-[45px] roboto font-light text-[14px] xl:text-[16px]">{t('main.44')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light text-[14px] xl:text-[16px]">{t('main.45')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light text-[14px] xl:text-[16px]">{t('main.46')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button border border-[#0349DA] mt-[40px] z-50 relative">
                            <p className="text-[#0349DA] underline">{t('main.47')}</p>
                        </div></Link>
                    </div>
                    <Fade right><div className="mt-[60px] xl:mt-0 mx-auto xl:mx-0">
                        <img src={support} alt="" />
                    </div></Fade>
                </div>
            </div>
        </div>
    );
}

export default SupportCenter;