import React from "react";

import $ from 'jquery';
import { useEffect, useState } from "react";
import { images } from "./imagesToLoad";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from './pages/Home'
import TradingDashboard from './pages/TradingDashboard'
import AssetMarket from './pages/AssetMarket'
import CommunityForum from "./pages/CommunityForum";
import SupportFaq from "./pages/SupportFaq";
import AboutsUs from "./pages/AboutsUs";


function App() {
    const [ isLoading, setIsLoading ] = useState(true);

    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const newImage = new Image();
        newImage.src = image;
        newImage.onload = () => {
          resolve(image);
        }
        newImage.onerror = (err) => reject(err);
      });
    };

    useEffect(() => {
      isLoading && $('body').addClass("ovf-hidden");
      Promise
        .all(images.map((image) => loadImage(image)))
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.log("Failed to load images", err)
          setIsLoading(false);
        });
        // eslint-disable-next-line
    }, []);
    

    useEffect(() => {
      !isLoading && $('body').removeClass("ovf-hidden");
      !isLoading && $('#loader').addClass("hide");
    }, [isLoading]); 
  return (
    <div>
      <Router>
        <Header/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/trading" element={<TradingDashboard />} />
            <Route path="/asset" element={<AssetMarket/>} />
            <Route path="/community" element={<CommunityForum/>} />
            <Route path="/support" element={<SupportFaq/>} />
            <Route path="/about" element={<AboutsUs/>} />
          </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
