import React from "react";

import { useTranslation } from "react-i18next";

function Join () {

    const { t } = useTranslation();

    return(
            <div className="max-w-[1280px] xl:mx-auto pt-[40px] xl:pt-[80px] pb-[40px] xl:pb-[80px] mx-[20px] flex flex-col">
               <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium text-center max-w-[617px] mx-auto">{t('main.6')}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-[40px] xl:mt-[80px] mx-auto gap-[60px]">
                    <div className="w-[290px] h-[174px] bg-[#D1DEF8] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <g clip-path="url(#clip0_300_380)">
                        <path d="M28.7164 20.071C27.9477 19.7056 27.1634 19.3909 26.3654 19.1272C28.95 17.1872 30.625 14.098 30.625 10.625C30.625 4.76641 25.8586 0 20 0C14.1413 0 9.37497 4.76641 9.37497 10.625C9.37497 14.1019 11.0537 17.1941 13.6432 19.1337C11.2707 19.9148 9.0445 21.1302 7.09606 22.7328C3.52325 25.6716 1.03911 29.7708 0.101376 34.2752C-0.19253 35.6866 0.159579 37.1363 1.06724 38.2524C1.97044 39.363 3.30974 40 4.74161 40H23.9843C24.8473 40 25.5468 39.3005 25.5468 38.4375C25.5468 37.5745 24.8473 36.875 23.9843 36.875H4.74161C4.07458 36.875 3.67216 36.5027 3.49169 36.2808C3.18005 35.8976 3.05942 35.3988 3.16075 34.9121C4.78224 27.1234 11.6522 21.4339 19.5858 21.242C19.7232 21.2473 19.8612 21.25 20 21.25C20.14 21.25 20.2795 21.2473 20.4183 21.2419C22.8495 21.2989 25.1878 21.8538 27.3748 22.8934C28.1543 23.2638 29.0863 22.9324 29.4568 22.153C29.8272 21.3736 29.4958 20.4415 28.7164 20.071ZM20.3811 18.1154C20.2543 18.1131 20.1272 18.112 20 18.112C19.8739 18.112 19.7478 18.1132 19.6218 18.1155C15.6613 17.918 12.5 14.6337 12.5 10.625C12.5 6.48945 15.8644 3.125 20 3.125C24.1355 3.125 27.5 6.48945 27.5 10.625C27.5 14.6327 24.3402 17.9163 20.3811 18.1154Z" fill="#001F61"/>
                        <path d="M38.4375 31.0156H34.1406V26.7188C34.1406 25.8558 33.4411 25.1562 32.5781 25.1562C31.7152 25.1562 31.0156 25.8558 31.0156 26.7188V31.0156H26.7188C25.8558 31.0156 25.1562 31.7152 25.1562 32.5781C25.1562 33.4411 25.8558 34.1406 26.7188 34.1406H31.0156V38.4375C31.0156 39.3005 31.7152 40 32.5781 40C33.4411 40 34.1406 39.3005 34.1406 38.4375V34.1406H38.4375C39.3005 34.1406 40 33.4411 40 32.5781C40 31.7152 39.3005 31.0156 38.4375 31.0156Z" fill="#001F61"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_300_380">
                        <rect width="40" height="40" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        <p className="mt-[14px] text-[16px] xl:text-[20px] font-medium text-[#001F61] manrope">{t('main.7')}</p>
                        <p className="mt-[10px] text-[14px] xl:text-[16px] font-light text-center textcolor roboto max-w-[161px]">{t('main.8')}</p>
                    </div>
                    <div className="w-[290px] h-[174px] bg-[#D1DEF8] flex flex-col justify-center items-center">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.7164 20.071C27.9478 19.7056 27.1635 19.3909 26.3654 19.1272C28.95 17.1872 30.625 14.098 30.625 10.625C30.625 4.76641 25.8587 0 20 0C14.1414 0 9.37503 4.76641 9.37503 10.625C9.37503 14.1019 11.0538 17.1941 13.6432 19.1337C11.2707 19.9148 9.04456 21.1302 7.09613 22.7328C3.52331 25.6716 1.03917 29.7708 0.101437 34.2752C-0.192469 35.6866 0.15964 37.1363 1.0673 38.2524C1.9705 39.363 3.3098 40 4.74167 40H23.9844C24.8474 40 25.5469 39.3005 25.5469 38.4375C25.5469 37.5745 24.8474 36.875 23.9844 36.875H4.74167C4.07464 36.875 3.67222 36.5027 3.49175 36.2808C3.18011 35.8976 3.05948 35.3988 3.16081 34.9121C4.7823 27.1234 11.6523 21.4339 19.5858 21.242C19.7232 21.2473 19.8613 21.25 20 21.25C20.1401 21.25 20.2796 21.2473 20.4183 21.2419C22.8496 21.2989 25.1878 21.8538 27.3749 22.8934C28.1543 23.2638 29.0864 22.9324 29.4568 22.153C29.8273 21.3736 29.4959 20.4415 28.7164 20.071ZM20.3811 18.1154C20.2543 18.1131 20.1273 18.112 20 18.112C19.8739 18.112 19.7478 18.1132 19.6219 18.1155C15.6614 17.918 12.5 14.6337 12.5 10.625C12.5 6.48945 15.8645 3.125 20 3.125C24.1356 3.125 27.5 6.48945 27.5 10.625C27.5 14.6327 24.3403 17.9163 20.3811 18.1154Z" fill="#001F61"/>
                        <g clip-path="url(#clip0_300_387)">
                        <path d="M38.2156 23.7135C38.4932 24.0044 38.6553 24.4057 38.6661 24.8291C38.6769 25.2524 38.5356 25.6632 38.2732 25.9711L30.1081 35.5556C29.9757 35.7106 29.8167 35.8347 29.6404 35.9205C29.4641 36.0063 29.274 36.052 29.0815 36.055C28.889 36.0579 28.6979 36.0181 28.5195 35.9378C28.341 35.8575 28.179 35.7384 28.0428 35.5875L23.72 30.7953C23.4655 30.4925 23.327 30.092 23.3335 29.6781C23.3401 29.2643 23.4913 28.8695 23.7553 28.5768C24.0193 28.2841 24.3755 28.1165 24.7488 28.1092C25.1221 28.1019 25.4834 28.2555 25.7565 28.5376L29.0322 32.1669L36.1791 23.7774C36.4416 23.4696 36.8035 23.29 37.1854 23.278C37.5673 23.266 37.9379 23.4226 38.2156 23.7135Z" fill="#001F61"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_300_387">
                        <rect width="15.3333" height="15.3333" fill="white" transform="translate(23.3333 22)"/>
                        </clipPath>
                        </defs>
                        </svg>
                        <p className="mt-[14px] text-[16px] xl:text-[20px] font-medium text-[#001F61] manrope">{t('main.9')}</p>
                        <p className="mt-[10px] text-[14px] xl:text-[16px] font-light text-center textcolor roboto max-w-[225px]">{t('main.10')}</p>
                    </div>
                    <div className="w-[290px] h-[174px] bg-[#D1DEF8] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <g clip-path="url(#clip0_300_393)">
                        <path d="M28.7164 20.071C27.9478 19.7056 27.1635 19.3909 26.3654 19.1272C28.95 17.1872 30.625 14.098 30.625 10.625C30.625 4.76641 25.8587 0 20 0C14.1414 0 9.37503 4.76641 9.37503 10.625C9.37503 14.1019 11.0538 17.1941 13.6432 19.1337C11.2707 19.9148 9.04456 21.1302 7.09613 22.7328C3.52331 25.6716 1.03917 29.7708 0.101437 34.2752C-0.192469 35.6866 0.15964 37.1363 1.0673 38.2524C1.9705 39.363 3.3098 40 4.74167 40H23.9844C24.8474 40 25.5469 39.3005 25.5469 38.4375C25.5469 37.5745 24.8474 36.875 23.9844 36.875H4.74167C4.07464 36.875 3.67222 36.5027 3.49175 36.2808C3.18011 35.8976 3.05948 35.3988 3.16081 34.9121C4.7823 27.1234 11.6523 21.4339 19.5858 21.242C19.7232 21.2473 19.8613 21.25 20 21.25C20.1401 21.25 20.2796 21.2473 20.4183 21.2419C22.8496 21.2989 25.1878 21.8538 27.3749 22.8934C28.1543 23.2638 29.0864 22.9324 29.4568 22.153C29.8273 21.3736 29.4959 20.4415 28.7164 20.071ZM20.3811 18.1154C20.2543 18.1131 20.1273 18.112 20 18.112C19.8739 18.112 19.7478 18.1132 19.6219 18.1155C15.6614 17.918 12.5 14.6337 12.5 10.625C12.5 6.48945 15.8645 3.125 20 3.125C24.1356 3.125 27.5 6.48945 27.5 10.625C27.5 14.6327 24.3403 17.9163 20.3811 18.1154Z" fill="#001F61"/>
                        <mask id="path-2-inside-1_300_393" fill="white">
                        <rect x="27" y="27" width="9" height="11" rx="1"/>
                        </mask>
                        <rect x="27" y="27" width="9" height="11" rx="1" stroke="#001F61" stroke-width="4.4" mask="url(#path-2-inside-1_300_393)"/>
                        <path d="M31 25H36.8333H37C37.5523 25 38 25.4477 38 26V33" stroke="#001F61" stroke-width="2.2" stroke-linecap="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_300_393">
                        <rect width="40" height="40" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        <p className="mt-[14px] text-[16px] xl:text-[20px] font-medium text-[#001F61] manrope">{t('main.11')}</p>
                        <p className="mt-[10px] text-[14px] xl:text-[16px] font-light text-center textcolor roboto max-w-[183px]">{t('main.12')}</p>
                    </div>
                </div>
                <a href='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] mx-auto">
                    {t('main.5')}
                </div></a>
            </div>
    );
}

export default Join;