import React from 'react';

import logo from "../img/logo.png";

import { useTranslation } from "react-i18next";

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#F7F4F8] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='w-[30px] h-[40px] ml-[30px] xl:ml-0'/>
        <div className='ml-[30px] xl:ml-[170px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.1')} +971521045347</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.2')} Unit 05, Level 6, Building 1 Currency House, DIFC, PO Box 507023, Dubai, UAE</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.3')} support@{hostname}</p>
          <a href="https://www.dfsa.ae/public-register/firms/canaccord-genuity-dubai-ltd"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('dop.12')}</p></a>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/docs/terms.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.4')}</p></a>
          <a href="/docs/policy.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.5')}</p></a>
          <a href="/docs/aml.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.6')}</p></a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[205px] pt-[40px]'>
        <p className='textcolor roboto text-[12px] font-normal'>{t('footer.8')}</p>
        <p className='textcolor roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer.7')}</p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] textcolor pr-[30px] xl:pr-0'>Trade Connect © 2024. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;