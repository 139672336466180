import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from "../components/CommunityForum/Main";
import CollaborativeLearning from "../components/CommunityForum/CollaborativeLearning";
import NetworkingOpportunities from "../components/CommunityForum/NetworkingOpportunities";
import TradeIdeasandStrategies from "../components/CommunityForum/TradeIdeasandStrategies";
import EducationalResources from "../components/CommunityForum/EducationalResources";

function CommunityForum () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <div className="overflow-hidden relative">
                <Main/>
                <LazyLoad><CollaborativeLearning/></LazyLoad>
                <LazyLoad><NetworkingOpportunities/></LazyLoad>
                <LazyLoad><TradeIdeasandStrategies/></LazyLoad>
                <LazyLoad><EducationalResources/></LazyLoad>
            </div>
        </>
    );
}

export default CommunityForum;