import React, { useState } from "react";

import leader from '../../img/SupportFaq/faq5.svg'
import team from '../../img/SupportFaq/faq1.svg'
import prof from '../../img/SupportFaq/faq2.svg'
import exchange from '../../img/SupportFaq/faq3.svg'
import t from '../../img/SupportFaq/faq4.svg'

import faq from '../../img/SupportFaq/faq.png'

import { useTranslation } from "react-i18next";

const faqData = [
    {
        question: 'supportfaq.28',
        answer: 'supportfaq.29',
        svg: leader
    },
    {
        question: 'supportfaq.30',
        answer: 'supportfaq.31',
        svg: t
    },
    {
        question: 'supportfaq.32',
        answer: 'supportfaq.33',
        svg: exchange
    },
    {
        question: 'supportfaq.34',
        answer: 'supportfaq.35',
        svg: prof
    },
    {
        question: 'supportfaq.36',
        answer: 'supportfaq.37',
        svg: team
    }
]

function FAQ () {
    
    const [openIndex, setOpenIndex] = useState(null);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                    <div>
                        <img src={faq} alt="" />
                    </div>
                    <div>
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div className={`w-[600px] flex justify-between mt-[20px] h-[60px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#FFF]' : 'bg-[#FFF]'}`}>
                                    <div>
                                        <div className="flex">
                                            <div className="w-[60px] h-[60px] flex items-center justify-center bg-[#D1DEF8]">
                                                <img src={faq.svg} alt="" className="w-[24px] h-[24px]"/>
                                            </div>
                                            <p className='mt-[15px] manrope font-medium text-[14px] xl:text-[20px] text-[#001F61] ml-[40px]'>{t(faq.question)}</p>
                                        </div>
                                        
                                        {openIndex === index && (
                                        <div className="flex">
                                            <div className="min-w-[60px] bg-[#D1DEF8]"></div>
                                            <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] ml-[40px]'>{t(faq.answer)}</p>
                                        </div>
                                        )}
                                    </div>
                                    <div className='cursor-pointer flex mx-[20px]' onClick={() => toggleText(index)}>
                                        <svg className={`mt-[25px] ${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 22 12" fill="none">
                                            <path d="M21.9999 0.842417L11 11.8423L1.31172e-07 0.842417L0.697128 0.145109L11 10.448L21.3028 0.145109L21.9999 0.842417Z" fill="#001F61"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;