import React, { useState } from "react";

import leader from '../../img/Community/leadership.svg'
import leader2 from '../../img/Community/leader.svg'
import team from '../../img/Community/team.svg'
import prof from '../../img/Community/prof.svg'
import exchange from '../../img/Community/exchange.svg'

import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next";

const faqData = [
    {
        question: 'CommunityForum.41',
        answer: 'CommunityForum.42',
        svg: leader
    },
    {
        question: 'CommunityForum.43',
        answer: 'CommunityForum.44',
        svg: leader
    },
    {
        question: 'CommunityForum.45',
        answer: 'CommunityForum.46',
        svg: exchange
    },
    {
        question: 'CommunityForum.47',
        answer: 'CommunityForum.48',
        svg: prof
    },
    {
        question: 'CommunityForum.49',
        answer: 'CommunityForum.50',
        svg: team
    },
    {
        question: 'CommunityForum.51',
        answer: 'CommunityForum.52',
        svg: leader2
    },
]

function NetworkingOpportunities () {
    
    const [openIndex, setOpenIndex] = useState(null);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] pt-[80px] xl:pt-[160px] ">
                <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('CommunityForum.37')}</h1>
                <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('CommunityForum.38')}</p>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-[20px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between mt-[20px] h-[60px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#FFF]' : 'bg-[#FFF]'}`}>
                                <div>
                                    <div className="flex">
                                        <div className="w-[60px] h-[60px] flex items-center justify-center bg-[#D1DEF8]">
                                            <img src={faq.svg} alt="" className="w-[24px] h-[24px]"/>
                                        </div>
                                        <p className='mt-[15px] manrope font-medium text-[14px] xl:text-[20px] text-[#001F61] ml-[40px]'>{t(faq.question)}</p>
                                    </div>
                                    
                                    {openIndex === index && (
                                    <div className="flex">
                                        <div className="min-w-[60px] bg-[#D1DEF8]"></div>
                                        <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] ml-[40px]'>{t(faq.answer)}</p>
                                    </div>
                                    )}
                                </div>
                                <div className='cursor-pointer flex mx-[20px]' onClick={() => toggleText(index)}>
                                    <svg className={`mt-[25px] ${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 22 12" fill="none">
                                        <path d="M21.9999 0.842417L11 11.8423L1.31172e-07 0.842417L0.697128 0.145109L11 10.448L21.3028 0.145109L21.9999 0.842417Z" fill="#001F61"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('CommunityForum.39')}</p>
                <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                    {t('CommunityForum.40')}
                </div></Link>
            </div>
        </div>
    );
}

export default NetworkingOpportunities;