import React, { useState, useEffect } from "react";

import main from '../../img/FAQ/main.jpg'
import main2 from '../../img/FAQ/2.jpg'
import main3 from '../../img/FAQ/3.jpg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();
    
    const [activeIndex, setActiveIndex] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const preloadImages = () => {
          const images = [main, main2, main3];
          images.forEach((image) => {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              if (images.indexOf(image) === images.length - 1) {
                setImagesLoaded(true);
              }
            };
          });
        };
    
        preloadImages();
      }, []);

    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="mt-[60px] xl:mt-[80px]">
                {imagesLoaded && (
                    <div className="w-full flex overflow-hidden">
                        <Fade bottom>{activeIndex === 0 && (<img src={main} alt="" className="object-cover w-full h-full"/>)}</Fade>
                        <Fade bottom>{activeIndex === 1 && (<img src={main2} alt="" className="object-cover w-full h-full"/>)}</Fade>
                        <Fade bottom>{activeIndex === 2 && (<img src={main3} alt="" className="object-cover w-full h-full"/>)}</Fade>
                    </div>
                )}
                <div className="flex flex-col items-center xl:mt-[-60px]">
                    <div className="bg-[#001F61] w-full h-[50px] xl:w-[806px] xl:h-[114px] flex mx-[20px] relative z-50 xl:mx-0 items-center justify-center gap-[40px] xl:gap-[80px]">
                        <p className={`transition-all manrope text-[16px] xl:text-[24px] font-medium cursor-pointer ${activeIndex === 0 ? 'text-[#FFF]' : 'textcolorwhite'}`} onClick={() => setActiveIndex(0)}>{t('dop.13')}</p>
                        <p className={`transition-all manrope text-[16px] xl:text-[24px] font-medium cursor-pointer ${activeIndex === 1 ? 'text-[#FFF]' : 'textcolorwhite'}`} onClick={() => setActiveIndex(1)}>{t('dop.14')}</p>
                        <p className={`transition-all manrope text-[16px] xl:text-[24px] font-medium cursor-pointer ${activeIndex === 2 ? 'text-[#FFF]' : 'textcolorwhite'}`} onClick={() => setActiveIndex(2)}>{t('dop.15')}</p>
                    </div>
                    <div className="max-w-[806px] mt-[35px] mx-[20px] xl:mx-0">
                        {activeIndex === 0 && (
                            <Fade>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px]">{t('Aboutus.1')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.2')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.3')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.4')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.5')}</p>
                            </Fade>
                        )}
                        {activeIndex === 1 && (
                            <Fade>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px]">{t('Aboutus.6')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.7')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.8')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.9')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.10')}</p>
                            </Fade>
                        )}
                        {activeIndex === 2 && (
                            <Fade>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px]">{t('Aboutus.11')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.12')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.13')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.14')}</p>
                                <p className="font-light textcolor roboto text-[14px] xl:text-[16px] mt-[20px]">{t('Aboutus.15')}</p>
                            </Fade>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;