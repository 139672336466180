import React from "react";

import { Link } from "react-router-dom"

import iphone from '../../img/Home/iphone.png'
import one from '../../img/Home/one.png'
import h1 from '../../img/Home/h1.png'
import h2 from '../../img/Home/h2.png'

import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="flex flex-col xl:flex-row justify-between ">
                <div className="mt-[100px] xl:mt-[140px] mx-[20px] xl:mx-0">
                    <img src={h2} alt="" className="hidden xl:block xl:absolute top-[100px] ml-[-90px]"/>
                    <h1 className="max-w-[331px] md:max-w-[608px] xl:max-w-[800px] manrope text-[#0349DA] text-[28px] md:text-[36px] xl:text-[48px] font-semibold relative z-10">{t('main.1')}<span className="text-black">{t('main.2')}</span></h1>
                    <p className="mt-[40px] textcolor roboto text-[14px] xl:text-[14px] font-light xl:max-w-[550px] z-50 relative">{t('main.3')}</p>
                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-normal xl:max-w-[550px] z-50 relative">{t('main.4')}</p>
                    <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                        {t('main.5')}
                    </div></Link>
                </div>
                <div className="overflow-hidden flex items-end justify-center xl:block">
                    <Bounce duration={1500} bottom><img src={iphone} alt="" className="z-10 absolute xl:right-[20%] xl:mt-[390px] md:w-[516px] md:h-[471px] w-[260.081px] h-[237.399px]"/></Bounce>
                    <Fade duration={2000} delay={500}><img src={one} alt="" className="xl:absolute xl:right-[10%] imgmain"/></Fade>
                    <Fade duration={1000} top><img src={h1} alt="" className="xl:mt-[160px] absolute scale-50 md:scale-75 xl:scale-100 mb-[220px] md:mb-[620px] xl:mb-0 xl:right-[40%]"/></Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;