import React, { useState, useRef, useEffect } from "react";

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function TradeIdeasandStrategies () {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);
    const [contentHeights, setContentHeights] = useState([]);
    const contentRefs = useRef([]);

    useEffect(() => {
        const heights = contentRefs.current.map(ref => ref.clientHeight);
        setContentHeights(heights);
      }, [activeIndex]);
    
      const blocks = [
        {
          title: 'CommunityForum.53',
          content: "CommunityForum.54",
        },
        {
          title: 'CommunityForum.55',
          content: "CommunityForum.56",
        },
        {
          title: 'CommunityForum.57',
          content: "CommunityForum.58",
        },
        {
          title: 'CommunityForum.59',
          content: "CommunityForum.60",
        },
        {
          title: 'CommunityForum.61',
          content: "CommunityForum.62",
        },
        {
          title: 'CommunityForum.63',
          content: "CommunityForum.64",
        },
        {
          title: 'CommunityForum.65',
          content: "CommunityForum.66",
        }
      ];

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[424px]">{t('CommunityForum.67')}</h1>
                        <p className="xl:max-w-[497px] textcolor mt-[40px] roboto font-light">{t('CommunityForum.68')}</p>
                        <p className="xl:max-w-[497px] textcolor mt-[20px] roboto font-light">{t('CommunityForum.69')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                            {t('CommunityForum.70')}
                        </div></Link>
                    </div>
                    <div>
                        <div className="flex mt-[40px] xl:h-[550px]">
                            <div>
                                <div className='w-[2px] h-[520px] xl:h-[550px] bg-[#D1DEF8] absolute'></div>
                                <div className={`w-[2px] bg-[#001F61] mt-[-430px] xl:mt-0 relative z-10`} style={{ height: `${contentHeights[activeIndex]+10}px`, top: `${contentRefs.current[activeIndex] ? contentRefs.current[activeIndex].offsetTop-188 : 0}px` }}></div>
                            </div>
                            <div  className="ml-[40px]">
                                {blocks.map((block, index) => (
                                    <div key={index} ref={el => contentRefs.current[index] = el}>
                                    <h1 className={`mt-[${index === 0 ? '0px' : '20px'}] cursor-pointer manrope text-[#001F61] text-[20px] font-medium`} onClick={() => setActiveIndex(index)}>{t(block.title)}</h1>
                                    {activeIndex === index && (
                                        <Fade><div>
                                        <p className={`h-[${block.height}px] xl:h-auto mt-[12px] max-w-[561px] textcolor roboto text-[16px] font-light`}>{t(block.content)}</p>
                                        </div></Fade>
                                    )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default TradeIdeasandStrategies;