import React, { useEffect } from "react";

import Main from '../components/AboutsUs/Main'
import LazyLoad from "react-lazyload";
import Features from "../components/AboutsUs/Features";
import Join from "../components/Home/Join";
import Regulation from "../components/AboutsUs/Regulation";

function AboutsUs () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <div className="overflow-x-hidden relative">
                <Main/>
                <Regulation/>
                <div className="mt-[80px] xl:mt-[160px]"></div>
                <LazyLoad><Features/></LazyLoad>
                <LazyLoad><Join/></LazyLoad>
            </div>
        </>
    );
}

export default AboutsUs;