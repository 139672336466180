import React from "react";

import iphone from '../../img/TradingDashboard/phone.png'
import h2 from '../../img/Home/h2.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function Main () {

    const { t } = useTranslation();
    
    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="mt-[100px] xl:mt-[140px]">
                    <img src={h2} alt="" className="hidden xl:block xl:absolute top-[100px] ml-[-90px]"/>
                    <h1 className="mx-[20px] xl:mx-0 max-w-[331px] md:max-w-[608px] xl:max-w-[800px] manrope text-[#0349DA] text-[28px] md:text-[36px] xl:text-[48px] font-semibold relative z-10">{t('tradingdashboard.1')}<span className="text-black">{t('tradingdashboard.2')}</span></h1>
                    <p className="mx-[20px] xl:mx-0 mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[550px] z-50 relative">{t('tradingdashboard.3')}</p>
                    <p className="mx-[20px] xl:mx-0 mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-normal xl:max-w-[550px] z-50 relative">{t('tradingdashboard.4')}</p>
                    <div className="mx-[20px] xl:mx-0 button bg-[#0349DA] mt-[40px] z-50 relative">
                        {t('tradingdashboard.5')}
                    </div>
                    <div class="line h-[199px] w-full absolute z-50 xl:hidden"></div>
                </div>
                <Link to='https://user.cgl-limited.net'><div className="flex flex-col items-end justify-center xl:block">
                    <Fade right><img src={iphone} alt="" className="xl:absolute right-0 mt-[2px] xl:mt-0"/></Fade>
                </div></Link>
            </div>
        </div>
    );
}

export default Main;